<template>
  <div id="app">
    <button @click="getFingerPrint">获取浏览器ID</button>
    <div id="bower-key">{{ fingerprint }}</div>
  </div>
</template>

<script>
import axios from 'axios'; // 引入axios进行HTTP请求
import {getCurrentBrowserFingerPrint} from "@rajesh896/broprint.js";

export default {
  name: 'App',
  components: {},
  data() {
    return {
      plugins: [],
      fingerprint: '', // 用于存储浏览器指纹的属性
      apiUrl: 'https://cjs.u003.com/submit' // 你的接口地址
    };
  },
  mounted() {
    this.getFingerPrint();
    let ss = this.generateFingerprint();
    console.log(ss)
    // this.detectFonts();
    this.getPlugins();
    this.submitData();
  },
  methods: {
    submitData() {

      getCurrentBrowserFingerPrint().then(fingerprint => {
        console.log(fingerprint)
        this.fingerprint = fingerprint;
        axios.post(this.apiUrl, {
          fingerprint: this.generateFingerprint(),
          hashId: fingerprint,
          // 其他你可能想提交的数据
        })
            .then(response => {
              console.log('提交成功:', response);
            })
            .catch(error => {
              console.error('提交错误:', error);
            });
      })

      // 创建提交数据的方法

    },
    getFingerPrint() {
      getCurrentBrowserFingerPrint().then(fingerprint => {
        console.log(fingerprint)
        this.fingerprint = fingerprint;
      })
    },
    getMaxTouchPoints() {
      return navigator.maxTouchPoints;
    },
    getHardwareConcurrency() {
      return navigator.hardwareConcurrency;
    },
    getUserAgent() {
      console.log("maxTouchPoints:" + navigator.maxTouchPoints);
      console.log("hardwareConcurrency:" + navigator.hardwareConcurrency);
      console.log("platform:" + navigator.platform);
      return navigator.userAgent;
    },
    getScreenResolution() {
      console.log(`${screen.width}x${screen.height}x${screen.colorDepth}`);
      return `${screen.width}x${screen.height}x${screen.colorDepth}`;
    },
    getBrowserLanguage() {
      console.log(navigator.language)
      return navigator.language;
    },
    getTimeZone() {
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    },
    generateFingerprint() {
      let userID = this.getUserID();
      return {
        userAgent: this.getUserAgent(),
        screenResolution: this.getScreenResolution(),
        language: this.getBrowserLanguage(),
        timeZone: this.getTimeZone(),
        maxTouchPoints: this.getMaxTouchPoints(),
        hardwareConcurrency: this.getHardwareConcurrency(),
        plugin: this.getPlugins(),
        userID: userID
      };
    },
    generateUniqueID() {
      return 'uid_' + Math.random().toString(36).substr(2, 9);
    },
    getUserID() {
      try {
        let userID = localStorage.getItem('userID');
        if (!userID) {
          userID = this.generateUniqueID();
          localStorage.setItem('userID', userID);
        }
        return userID;
      } catch (e) {
        //console.error('localStorage操作失败:', e);
        // 可以考虑使用备用机制来存储userID
        return -1;
      }
    },
    detectFonts() {
      const detectedFonts = [];
      const baseFonts = ['monospace', 'sans-serif', 'serif'];

      const testString = "mmmmmmmmmmlli";
      const testSize = '72px';

      let baseWidths = {};
      let testSpan = document.createElement("span");
      document.body.appendChild(testSpan);

      for (let baseFont of baseFonts) {
        testSpan.style.fontFamily = baseFont;
        testSpan.style.fontSize = testSize;
        testSpan.innerHTML = testString;
        baseWidths[baseFont] = testSpan.offsetWidth;
      }

      for (let font of this.fonts) {
        for (let baseFont of baseFonts) {
          testSpan.style.fontFamily = font + ',' + baseFont;
          testSpan.innerHTML = testString;
          if (testSpan.offsetWidth !== baseWidths[baseFont]) {
            detectedFonts.push(font);
            break;
          }
        }
      }

      document.body.removeChild(testSpan);
      console.log("detectedFonts:" + detectedFonts);
      return detectedFonts;
    },
    getPlugins() {
      const plugins = [];
      for (let i = 0; i < navigator.plugins.length; i++) {
        plugins.push(navigator.plugins[i].name);
      }
      console.log("p" + plugins);
      return plugins;
    }


  }

}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
